# This toggles uls nested in li.has-submenu elements
class window.SubmenuToggler
  TOGGLE_SELECTOR: "toggling"

  @initTogglers: (scope) ->
    submenuTogglers = if scope == undefined then $("li.has-submenu") else $(scope).find("li.has-submenu")
    submenuTogglers.each -> new SubmenuToggler(this)

  constructor: (container) ->
    @container = $(container)
    @activator = @container.find("a")
    @submenu   = @container.find("ul")
    @isShown   = false
    @toggler   = new Toggler @submenu, "fast", => @submenu.removeClass(@TOGGLE_SELECTOR)
    @setTrigger()

  setTrigger: ->
    @container.on "mouseleave mouseenter", (e) => @handleEvent(e)
    @activator.on "click", (e) => @handleEvent(e)

  handleEvent: (event) ->
    # Mobile has weird behavior that causes first click event to also trigger hover events.
    # So must handle hover and click events separately in order to prevent double toggle.
    switch event.type
      when "mouseenter", "mouseleave" then @handleHover(event.type == "mouseenter")
      when "click"                    then @handleClick()

  handleHover: (show) ->
    @submenu.stop().addClass(@TOGGLE_SELECTOR)
    @isShown = show
    @toggleSubmenu(@isShown)

  handleClick: ->
    # Prevent toggling again on click when already in process of toggling
    unless @submenu.hasClass(@TOGGLE_SELECTOR)
      @submenu.addClass(@TOGGLE_SELECTOR)
      @isShown = !@isShown
      @toggleSubmenu(@isShown)

  toggleSubmenu: (show) -> if show then @toggler.show() else @toggler.hide()

$(document).on "js.load", -> SubmenuToggler.initTogglers()
