window.initInPlaceEditor = (editor, afterSubmit = null) ->
  editor = $(editor)
  form = editor.find(".in-place-editor-form")
  cancelBtn = form.find(".in-place-editor-cancel")
  display = editor.find(".in-place-editor-display")

  hide = ->
    form.hide()
    display.show()

  show = ->
    display.hide()
    form.show()

  # toggle form to show display
  cancelBtn.on "click", -> hide()

  # toggle display to show form
  display.on "click", -> show()

  # submit form
  form.on "ajax:success", (e, resp) ->
    hide()
    afterSubmit(resp) if $.isFunction(afterSubmit)
