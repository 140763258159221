class PageVisibilityToggle
  constructor: (toggle) ->
    @toggle = $(toggle)
    @form = @toggle.find("form")
    @button = @form.find(":submit")
    @tippy = @form[0]._tippy

    @form.on "ajax:success", (e, data) => @updateStatus(data.visible)

  updateStatus: (isVisible) ->
    oldBtn = @button

    if isVisible
      @tippy.setContent('Page is currently visible to non-admins')
      @button = @button.clone()
      @button.removeClass('warning').addClass('success')
      @button.find(".fa").removeClass('fa-spin fa-spinner fa-times').addClass("fa-check")
    else
      @tippy.setContent('Page is currently NOT visible to non-admins')
      @button = @button.clone()
      @button.removeClass('success').addClass('warning')
      @button.find(".fa").removeClass('fa-spin fa-spinner fa-check').addClass("fa-times")

    oldBtn.replaceWith @button

$(document).on "page_contents/index.load", -> new PageVisibilityToggle(toggle) for toggle in $(".page-toggle")