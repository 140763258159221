class window.DemographicSearch
  constructor: (select, options={}) ->
    @select = $(select)
    @setup(options)

  @init: (container) ->
    container.find("select.demographic-search").each (idx, s) => new DemographicSearch(s)

  setup: (options) ->
    selectOptions = $.extend({}, @defaultOptions(), options)
    @select.selectize selectOptions

  defaultOptions: ->
    inactive         = @select.data("search-inactive") || false
    legacy           = @select.data("search-legacy") || false
    userOnly         = @select.data("search-user-only") || false
    includeEmail     = @select.data("show-email") || false
    availableOptions = @select.data("available-options")
    availableOptions = [availableOptions] unless $.isArray(availableOptions)
    selectedOptions  = @select.data("selected-options")
    selectedOptions  = [selectedOptions] unless $.isArray(selectedOptions)

    {
      valueField: "id"
      labelField: "full_name"
      searchField: ["fema_sid", "full_name"]
      options: availableOptions
      items: selectedOptions
      render:
        option: (item, escape) ->
          femaSid = if item.fema_sid? then "<strong>#{escape(item.fema_sid)}</strong>" else "<em>(No FEMA SID)</em>"
          name = escape(item.full_name)
          mainText = name
          mainText = name + " <em>(#{escape(item.email)})</em>" if includeEmail

          "<div>" +
            "<span class='name'>" +
              "#{femaSid}<span class='muted'> &mdash; #{mainText}</span>" +
            "</span>" +
          "</div>"

      load: (query, callback) =>
        $.ajax
          url: @select.data("load-path")
          type: "GET"
          dataType: "json"
          data: {q: query, excluded: @getExcluded(), legacy: legacy, inactive: inactive, user_only: userOnly}
          error: => callback()
          success: (res) => callback(res)
    }

  getExcluded: =>
    excluded = @select.data("excluded")
    excluded = new Array if excluded == undefined
    selected = @select.val()

    # exclude already selected values from new options
    if selected?
      selected = [selected] unless $.isArray(selected)

      for value in selected
        excluded.push(value) if $.inArray(value, excluded) < 0

    excluded

class DemographicFields
  constructor: ->
    # This conditionally assigns required field validation on county field of demographic fields.
    # If the region field is set to "Out-of-state", then county shouldn't be required.
    @regionField = $("select[data-out-of-state-region]")
    @countyField = @regionField.closest("form").find("select[name$='[county_id]']")

    return false unless @regionField.length > 0 && @countyField.length > 0

    outOfStateVal = @regionField.data("out-of-state-region").toString()

    # disable county selection if out-of-state selected in region field
    @regionField.on "change", (e) =>
      if @regionField.val().toString() == outOfStateVal
        @countyField
          .attr "disabled", "disabled"
          .removeAttr "data-parsley-required"
          .find "option"
          .attr "disabled", "disabled"
          .filter "[value='']"
          .removeAttr "disabled"
          .prop "selected", true
          .text "Out-of-State"
      else
        @countyField
          .removeAttr "disabled"
          .attr "data-parsley-required", ""
          .find "option"
          .removeAttr "disabled"
          .filter "[value='']"
          .text "-- Select one --"

    .trigger("change")

    # auto-assign region from county field if region is blank
    @countyField.on "change", (e) =>
      if @countyField.val() != "" && @regionField.val() == ""
        regionId = @countyField.find("option:selected").data("region-id")
        @regionField.val(regionId) if regionId != "" && regionId != undefined

$(document).on "admin/index.load demographics/new.load demographics/create.load " +
               "demographics/edit.load demographics/update.load " +
               "users/new.load users/create.load " +
               "users/edit.load users/update.load " +
               "demographics/conflicts/edit.load demographics/conflicts/update.load" , =>
  new DemographicFields

$(document).on "js.load", ->
  searchFields = $(".demographic-search")
  new DemographicSearch(searchField) for searchField in searchFields

$(document).on "users/show.load demographics/show.load", ->
  history = $(".course-history")
  printBtn = $(".profile-print-button")

  printBtn.on "click", ->
    history.addClass("noprint")
    print()

    history.removeClass("noprint")
