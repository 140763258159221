class window.ImportStatusForm
  ERROR_MSG: "<span><i class='fa fa-warning'></i> An error occurred while loading this import data.</span>"

  constructor: (form) ->
    @form = $(form)
    @setup()

  setup: ->
    new Form(@form)

    DemographicSearch.init(@form)
    CourseSearch.init(@form)

    @form.on "ajax:success", (e, resp) => @updateStatus(resp)

  updateStatus: (formResponse) ->
    container = @form.closest(".import-summary")
    container.removeClass("error").addClass("success")

    newStatus = "<small style='margin-left: 0.5rem;'>" +
                  "<a href='#{formResponse.demographic_path}'>#{formResponse.demographic_name}</a> " +
                  "successfully imported for " +
                  "<a href='#{formResponse.course_path}'>#{formResponse.course_title}</a>!" +
                "</small>"

    @form.replaceWith(newStatus)

class ImportSummary
  constructor: -> @loadData()
  loadData: ->
    ph = $(".async-content-placeholder")
    loadPath = ph.data("load-path")

    $.get loadPath, (resp) =>
      ph.replaceWith(resp)
      @setupForms()
    .fail => ph.addClass("error-msg").html(@ERROR_MSG)

  setupForms: ->
    $(".import-summary").each (idx, status) =>
      form = $(status).find("form")
      new ImportStatusForm form

class ImportIndex
  constructor: (object) ->
    @table = $("#imports-table")
    @setup()

  setup: -> @loadPolled()

  loadPolled: ->
    items = @table.find("[data-poll]")
    @poll($(item)) for item in items

  poll: (item) ->
    url = item.data("poll")
    request = =>
      setTimeout =>
        $.get url, (data) =>
          status = data.status
          @updateStatus item, status
          if status == "queued" || status == "importing"
            request()
          else
            item.find("td:last a").removeAttr("disabled")
      , 3750

    request()

  updateStatus: (item, status) ->
    statusCell = item.find("td.status")
    return false if status == "queued" # status should already be set

    statusHtml =
      switch status
        when "importing" then "<span class='muted'><i class='fa fa-fw fa-spin fa-sync'></i> Importing</span>"
        when "completed" then "<span class='success-text'><i class='fa fa-fw fa-check'></i> Completed</span>"
        when "failed" then "<span class='warning-text'><i class='fa fa-fw fa-times'></i> Failed</span>"

    statusCell.html statusHtml

$(document).on "imports/show.load", -> new ImportSummary
$(document).on "imports/index.load", -> new ImportIndex