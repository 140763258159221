class window.FacilitySearch
  constructor: (selectField) ->
    @selectField = $(selectField)
    @init()

  init: ->
    @selectField.selectize {
      render:
        option: (item, escape) ->
          nameLine = "<strong>#{escape(item.text)}</strong>"
          addressText = if item.address then escape(item.address) else "<em>No Address</em>"
          addressLine = "<span class='muted'>#{addressText}</span>"

          "<div>#{nameLine}<br/>#{addressLine}</div>"

        item: (item, escape) ->
          addressText = if item.address then escape(item.address) else "<em>No Address</em>"

          "<div><strong>#{item.text}</strong><span class='muted'> &mdash; #{addressText}</span></div>"
    }


class window.Form
  @ERRORS_SELECTOR: ".form-errors"
  PHONE_MASK: new Inputmask("(999) 999-9999")

  @initForms: (scope) ->
    forms = if scope == undefined then $("form") else $(scope).find("form")
    forms.each -> new Form(this)

  # clear fields and errors
  @reset: (form) ->
    # clear input values
    form.trigger("reset")
    # clear parsley validation errors
    form.parsley().reset() if form.find(".parsley-errors-list.filled").length > 0
    # remove errors
    form.find(@ERRORS_SELECTOR).remove()

  @uploadFilesAndSubmit: (form) ->
    @disableAllSubmitButtons(form)

    inputsWithFiles = form.find("input[type='file'][data-direct-upload-url]:enabled")
    inputsWithFiles = inputsWithFiles.filter (idx, input) -> input.files.length > 0

    # just submit form unless files need to be uploaded
    if inputsWithFiles.length < 1
      @submit(form)
    else
      for input in inputsWithFiles
        FileUpload.upload input, =>
        # @uploadFiles input, =>
          inputsWithFiles = inputsWithFiles.not(input)

          # only submit form once all file inputs have their files uploaded
          @submit(form) unless inputsWithFiles.length > 0


  @submit: (form) ->
    @disableAllSubmitButtons(form)

    data = form.serialize()
    url  = form.attr("action")

    if url != ""
      $.post url, data, (resp) =>
        unless resp == undefined
          if resp.redirect_to != undefined
            window.location.href = resp.redirect_to
          else if resp.flash != undefined
            type = Object.keys(resp.flash)[0]
            text = Object.values(resp.flash)[0]

            new Flash(text, {type: type})

        form.trigger("ajax:success", resp)
      , "json"
      .fail (resp) =>
        errors = resp.responseJSON["errors"]

        # reload recaptcha
        if form.find(".g-recaptcha").length > 0
          grecaptcha.reset()

        Form.addErrors(form, errors)
      .always (resp) =>
        # re-enable submit buttons, if staying on current page
        if resp.redirect_to == undefined
          @enableAllSubmitButtons(form)

        form.trigger("ajax:complete", resp)
    else
      @enableAllSubmitButtons(form)

  @enableAllSubmitButtons: (form) ->
    for btn in form.find(":submit:disabled")
      @enableBtn $(btn)

  @disableAllSubmitButtons: (form) ->
    for btn in form.find(":submit:enabled")
      @disableBtn $(btn)

  @enableBtn: (btn) ->
    origText = btn.data("original-text")
    @setBtnValue(btn, origText)
    btn.removeAttr("disabled")

  @disableBtn: (btn) ->
    origText = @getBtnValue(btn)
    disableText = btn.data("disable-with")
    disableText = "Submitting..." if disableText == undefined

    btn.attr("disabled", "disabled")
       .data("original-text", origText)

    @setBtnValue(btn, disableText)

  @getBtnValue: (btn) -> if btn[0].tagName == "BUTTON" then btn.html() else btn.val()
  @setBtnValue: (btn, val) -> if btn[0].tagName == "BUTTON" then btn.html(val) else btn.val(val)

  # display errors list on form
  @addErrors: (form, errors) ->
    errors = @buildErrorMsgs(errors) if $.isArray(errors)

    # remove existing errors
    form.find(@ERRORS_SELECTOR).remove()

    # add new errors to DOM, but hidden from view
    form.prepend(errors.hide().addClass("animation fade-in-down"))

    container  = form.closest(".tingle-modal")
    container  = $("html, body") if container.length < 1

    errors.show ->
      # figure out what container we need to scroll on to display errors
      container  = form.closest(".tingle-modal")
      container  = $("html, body") if container.length < 1

      # reset scroll so we're at the top of the container
      container.scrollTop(0)

      # find top of errors
      top = errors.offset().top

      # scroll errors into window view and display
      container.animate {scrollTop: "#{top}px"}, 500

  # converts array of strings into error list
  @buildErrorMsgs: (messages) ->
    title     = "#{messages.length} #{if messages.length != 1 then "Errors" else "Error"} Prevented This Form From Saving:"
    errors    = $("<div class='form-errors'></div>")
    errorList = $("<ul></ul>")

    for message in messages
      errorList.append("<li>#{message}</li>")

    errors.append("<p>#{title}</p>").append(errorList)

  constructor: (form) ->
    @form = $(form)

    # initialize validator (comment out to disable)
    # !! MUST BE INIT'D BEFORE @handleSubmit()
    @validator = @form.parsley() if @form.filter("[data-validate]").length > 0

    unless @form.data("ajax") == false
      @form.on "submit", (event) => @handleSubmit(event)

    # set triggers for address fields if present in form
    @setupAddressFields() if @form.find(".address-fields").length > 0

    # init conflict search class if it's needed
    new ConflictSearch(@form)

    # initialize phone inputs
    for phoneInput in @form.find("input.phone-input")
      @PHONE_MASK.mask(phoneInput)

  setupAddressFields: ->
    $container = @form.find(".address-fields")

    # if fieldset isn't marked as required, only require required address fields if some are populated
    unless $container.data("required")
      $allFields      = $container.find(":input")
      $requiredFields = $allFields.filter("[data-parsley-required]")

      $allFields.on "change", =>
        $populatedFields = $allFields.filter -> this.value != "" && this.value != undefined

        if $populatedFields.length > 0
          $requiredFields.attr("data-parsley-required", "")
        else
          $requiredFields.removeAttr("data-parsley-required")

      .first().trigger("change") # run after initial page load

  handleSubmit: (event) ->
    event.preventDefault()
    @constructor.uploadFilesAndSubmit(@form)


$(document).on "js.load", ->
  setTimeout (->
    Form.initForms()
    return
  ), 1
# wait 1ms for other files to load
