Quill = require('quill')

class EditorRenderer
  constructor: (renderer) ->
    @content = $(renderer)
    @displayContent()

  displayContent: ->
    rendererContent = @content.data "content"
    renderer = new Quill @content[0], readOnly: true
    renderer.setContents(rendererContent)

class Editor
  TOOLBAR_OPTIONS: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],        # toggled buttons
    [{ 'script': 'sub'}, { 'script': 'super' }],      # superscript/subscript
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'color': [] }, { 'align': [] }],
    ['clean'],
  ]

  constructor: (editorField) ->
    @editorField = $(editorField)
    @editor      = new Quill @editorField[0],
      theme: "snow"
      modules: {toolbar: @TOOLBAR_OPTIONS}
      placeholder: "Add page content..."

    @form = @editorField.closest("form")
    @initForm() if @form.length > 0

  initForm: ->
    contentField = @form.find("input[name$='[content]']")

    if contentField.length > 0
      @form.on "click", ":submit", (e) =>
        value = JSON.stringify(@editor.getContents())
        contentField.val(value)

      unless contentField.val() == ""
        initContent = JSON.parse(contentField.val())
        @editor.setContents(initContent)

$(document).on "js.load", ->
  editorFields = $(".editor")
  new Editor(editorField) for editorField in editorFields

$(document).on "js.load", ->
  renderers = $(".editor-renderer")
  new EditorRenderer(renderer) for renderer in renderers