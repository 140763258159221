class ExerciseDocumentImportSummary
  constructor: ->
    @statusHolder = $(".import-status")

    @poll(@statusHolder) if @statusHolder.length > 0

  poll: (item) ->
    url = item.data("poll")
    request = =>
      setTimeout =>
        $.get url, (data) =>
          status = data.status
          @updateStatus item, status

          if status == "completed"
            window.location.reload(true)
          else
            request()

      , 2000

    request()

  updateStatus: (item, status) ->
    return false if status == "queued" # status should already be set

    statusHtml =
      switch status
        when "importing" then "<span class='muted'><i class='fa fa-fw fa-spin fa-sync'></i> Importing</span>"
        when "completed" then "<span class='success-text'><i class='fa fa-fw fa-check'></i> Completed</span>"
        when "failed" then "<span class='warning-text'><i class='fa fa-fw fa-times'></i> Failed</span>"

    @statusHolder.html statusHtml

$(document).on "exercise_document_imports/show.load", ->
  new ExerciseDocumentImportSummary()