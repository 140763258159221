class CourseOfferingNotificationForm
  constructor: (form) ->
    @form = $(form)
    @previewSubmit = @form.find("#preview-submit")
    @validator = @form.parsley()

    submitUrl = @form.attr("action")
    previewUrl = @previewSubmit.data("preview-path")

    @previewSubmit.on "click", (e) =>
      # tell form to submit to the preview URL
      @form.attr("action", previewUrl)
      # disable file inputs so they don't get uploaded for the preview
      @form.find(".file :input").attr("disabled", "disabled")

      Form.submit(@form)

    @form.on "ajax:complete", (e) =>
      # reset url in case preview url set
      @form.attr("action", submitUrl)

      # re-enable inputs in case disabled from preview
      @form.find(".file :input").removeAttr("disabled")


$(document).on "course_offering_notifications/new.load course_offering_notifications/create.load", ->
  form = $(".course-offering-notification-form")
  new CourseOfferingNotificationForm(form) if form.length > 0