class ExerciseParticipantForm
  constructor: (form) ->
    @form = $(form)
    @initForm()

  initForm: ->
    statusField    = @form.find("select[name$='[status]']")
    cancelSubField = @form.find("#cancel-reason-subfields")
    cancelToggler  = new Toggler(cancelSubField)

    statusField.on "change", ->
      if statusField.val() == "cancelled" then cancelToggler.show() else cancelToggler.hide()


class ExerciseForm
  constructor: (form) ->
    @form          = $(form)
    @sponsorField  = @form.find("[name$='[sponsor]']")
    @cityField     = @form.find("[name$='[city_id]']")
    @countyField   = @form.find("[name$='[county_id]']")
    @pubMedField   = @form.find("[name$='[public_health_and_medical]']:visible")
    @addObjBtn     = @form.find(".add-exercise-objective")
    @newFacilities = []

    @initForm()

  disableCityField: -> @cityField[0].selectize.disable()
  enableCityField: -> @cityField[0].selectize.enable()

  initForm: ->
    sponsorSubFields        = @form.find("#local-sponsor-subfields")
    sponsorSubFieldsToggler = new Toggler(sponsorSubFields)

    alternateSubFields        = @form.find("#alternate-sponsor-subfields")
    alternateSubFieldsToggler = new Toggler(alternateSubFields)

    @addObjBtn.on "association.added", => @initializeNewObjFields()

    @sponsorField.on "change", =>
      if @sponsorField.filter("[value='locally_sponsored']").is(":checked")
        sponsorSubFieldsToggler.show()
      else
        sponsorSubFieldsToggler.hide()
    .trigger("change")

    @sponsorField.on "change", =>
      if @sponsorField.filter("[value='alternately_sponsored']").is(":checked")
        alternateSubFieldsToggler.show()
      else
        alternateSubFieldsToggler.hide()
    .trigger("change")

    @cityField.on "change", =>
      if @cityField.val() != ""
        @countyField.attr("disabled", "disabled")
      else
        @countyField.removeAttr("disabled")
    .trigger("change")

    @countyField.on "change", =>
      if @countyField.val() != ""
        @disableCityField()
      else
        @enableCityField()
    .trigger("change")

    @pubMedField.on "change", =>
      for objectiveFields in @form.find(".exercise-objective-fields")
        fields = $(objectiveFields)
        @toggleHealthFields(fields)
    .trigger("change")

    @initDateTimeFields()
    @initObjectiveFields()
    @initFacilityFields(@form)

  toggleHealthFields: (fields) -> if @pubMedField.is(":checked") then @enableHealthFields(fields) else @disableHealthFields(fields)

  enableHealthFields: (fields) ->
    objField = fields.find("[name$='[objective]']")
    healthCapField = fields.find("[name$='[health_capability_ids][]']")
    coreCapField = fields.find("[name$='[core_capability_ids][]']")

    coreCapField.attr("data-parsley-required", false)
    coreCapField.closest(".col-12").addClass("col-3-m")
    objField.closest(".col-12").removeClass("col-6-m").addClass("col-12-m")
    healthCapField.closest(".col-12").show()

  disableHealthFields: (fields)  ->
    objField = fields.find("[name$='[objective]']")
    healthCapField = fields.find("[name$='[health_capability_ids][]']")
    coreCapField = fields.find("[name$='[core_capability_ids][]']")

    coreCapField.attr("data-parsley-required", true)
    coreCapField.closest(".col-12").removeClass("col-3-m")
    objField.closest(".col-12").removeClass("col-12-m").addClass("col-6-m")
    healthCapField.closest(".col-12").hide()

  initializeNewObjFields: ->
    targetSelector = @addObjBtn.data("append-to")
    target         = $(targetSelector)
    newFields      = target.children().last()

    newFields.find("[data-selectize]").selectize()
    @toggleHealthFields(newFields)

  copyPrevFieldValues: (newFields) ->
    fields     = $(newFields)
    prevFields = fields.prevAll(".exercise-date-range-fields").first()

    # copy previous field values to new fields
    if prevFields.length > 0
      prevFacilityField = prevFields.find("[name$='[facility_id]']")
      prevEndDateField  = prevFields.find("[name$='[end_date]']")
      prevTimeField     = prevFields.find("[name$='[time]']")

      fields.find("[name$='[facility_id]']").val(prevFacilityField.val())
      fields.find("[name$='[start_date]'], [name$='[end_date]']").val(prevEndDateField.val())
      fields.find("[name$='[time]']").val(prevTimeField.val())

  initFacilityFields: (scope) ->
    container = $(scope)
    facilityFields = container.find("[name$='[facility_id]']")

    # initialize modal for adding new facilities
    container.find(".new-option-modal[data-modal]").on "modal:loaded", (e, modal) => @initFacilityModal(e.currentTarget, modal)

    # initialize selectize
    new FacilitySearch(facilityFields)

  initDateTimeFields: ->
    addButton = @form.find(".add-exercise-date-range")
    addButton.on "association.added", (event, newFields) =>
      @initFacilityFields(newFields)
      @addNewFacilityOption(newFields, newOpt) for newOpt in @newFacilities # add newly created facilities to option list
      @copyPrevFieldValues(newFields)
      DateInput.initInputs(newFields) # init date inputs

    @form.on "click", ".remove-exercise-date-range", (event) =>
      dateRangeFields = $(event.currentTarget).closest(".exercise-date-range-fields")
      dateRangeFields.slideUp ->
        if dateRangeFields.data("persisted")
          idField = dateRangeFields.next(":input:hidden")
          deleteField = idField.clone().attr "name", (idx, val) -> val.replace(/\[id\]/, "[_destroy]")

          dateRangeFields.replaceWith(deleteField)
        else
          dateRangeFields.remove()

  initObjectiveFields: ->
    @form.on "click", ".remove-exercise-objective", (event) =>
      objectiveFields = $(event.currentTarget).closest(".exercise-objective-fields")
      objectiveFields.slideUp ->
        if objectiveFields.data("persisted")
          idField = objectiveFields.next(":input:hidden")
          deleteField = idField.clone().attr "name", (idx, val) -> val.replace(/\[id\]/, "[_destroy]")

          objectiveFields.replaceWith(deleteField)
        else
          objectiveFields.remove()

  initFacilityModal: (trigger, modal) ->
    modalTrigger = $(trigger)
    modalElement = $(modal.modal)
    modalForm    = modalElement.find("form")

    modalForm.on "ajax:success", (event, resp) =>
      # add to list of new facilities so it gets added to appended date range fields
      @newFacilities.push(resp)
      # adds option to existing facility fields
      @addNewFacilityOption(@form, resp)
      # select new facility option for facility field
      input = modalTrigger.closest(".field-group").find(":input[name$='[facility_id]']")
      selectize = input[0].selectize
      selectize.setValue(resp.id)

      modal.close() # hide modal
      Form.reset(modalForm) # clear fields and errors

  addNewFacilityOption: (scope, option) ->
    facilityFields = $(scope).find(":input[name$='[facility_id]']")

    for field in facilityFields
      selectize = field.selectize
      selectize.addOption({text: option.name, value: option.id, address: option.address})

class ExerciseDocumentModal
  constructor: (modal, summary) ->
    @summary = summary
    @modal = modal
    @form = $(modal.modal).find("form")
    @form.on "ajax:success", (e, resp) => @handleSubmit(resp)

  handleSubmit: (formResponse) ->
    @resetForm()
    @modal.close()

    @summary.addDocument(formResponse)

  resetForm: ->
    @form[0].reset()
    @form.find(":input:file").removeAttr("disabled").siblings(".progress, :input[type='hidden']").remove()
    @form.find("#doc-other-category-subfields").hide()

class ExerciseSummary
  constructor: ->
    @documentList = $(".documents-table tbody")
    @completionBtnMask = $("#exercise-complete-button-disabled-mask")

    @documentModal()

  documentModal: ->
    documentModalBtn = $(".exercise-document-modal-button")
    documentModalBtn.on "modal:loaded", (e, modal) => new ExerciseDocumentModal(modal, this)

  formatDocumentType: (type) ->
    switch type
      when "sign_in_sheet" then "Sign-in Sheet"
      when "ce_handbook"   then "Controller / Evaluator (C/E) Handbook"
      when "explan"        then "Exercise Plan (ExPlan)"
      when "sitman"        then "Situation Manual (SitMan)"
      when "flyer"         then "Exercise Flyer"
      when "msel"          then "Master Scenario Events List (MSEL)"
      when "aar"           then "After Action Report"
      when "ip"            then "Improvement Plan"
      when "powerpoint"    then "PowerPoint Presentation"
      else
        Formatter.capitalize(type)

  documentTypeList: (types) ->
    types = types.map (type) => @formatDocumentType(type)

    if types.length > 2
      lastType = types.pop()
      types.join(", ") + ", and #{lastType}"
    else
      types.join(" and ")

  # mark any new fulfilled requirements as completed
  updateRequirementsList: ->
    $(".exercise-requirement-indicator").each (idx, item) =>
      req = $(item)
      return if req.find(".fa-check").length > 0 # already marked completed

      if $.inArray(req.data("type"), @uploadedDocumentTypes()) >= 0
        req.find(".fa-times").addClass("fa-check success-text").removeClass("fa-times warning-text")

  # gives list of types for all uploaded documents
  uploadedDocumentTypes: -> @documentList.find("tr").map (idx, item) -> $(item).data("types")

  # removes disabled tooltip mask from completion button if all requirements met
  updateCompletionButton: ->
    return unless @completionBtnMask.length > 0
    return unless @completionBtnMask.data("ended")

    if $(".exercise-requirement-indicator").find(".fa-times").length <= 0
      @completionBtnMask.children("a").removeAttr("disabled").unwrap()
      @completionBtnMask = $() # make empty for subsequent checks

  # shows new documents in document list and calls other functions page based on completed requirements
  addDocument: (formResponse) ->
    row = $("<tr></tr>")
    row.append("<td><a href='#{formResponse.url}'>#{formResponse.name}</a></td>")
    row.append("<td>#{@documentTypeList(formResponse.types)}</td>")
    row.append("<td>#{Formatter.formatDateTime(formResponse.created_at)}</td>")
    row.append("<td class='text-center shrink'><a href='/exercises/#{formResponse.exercise_id}/documents/#{formResponse.id}' data-confirm=\"Are you sure you'd like to delete this document?\" data-method='delete' rel='nofollow'><i class='fa fa-trash'></i> Delete Document</a></td>")
    row.data("types", formResponse.types)

    @documentList.find(".empty").remove()
    @documentList.append(row)

    @updateRequirementsList()
    @updateCompletionButton()

$(document).on "exercises/new.load exercises/create.load exercises/edit.load exercises/update.load", ->
  form = document.querySelector(".exercise-form")
  new ExerciseForm(form) if form?


$(document).on "exercises/index.load exercises/past/index.load", ->
  facilityField = document.querySelector(".exercise-facility-filter")
  new FacilitySearch(facilityField) if facilityField?


$(document).on "exercises/show.load", -> new ExerciseSummary()


$(document).on "exercise_participants/new.load " +
               "exercise_participants/create.load " +
               "exercise_participants/edit.load " +
               "exercise_participants/update.load", ->

  form = document.querySelector(".exercise-participant-form")
  new ExerciseParticipantForm(form) if form?
