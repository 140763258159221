class window.Flash
  @HIDE_ANIMATION: "fade-out-up"
  @SHOW_ANIMATION: "fade-in-down"
  AUTOHIDE_DURATION: 5 * 1000 # 5 seconds

  @hide: ($flash) ->
    $flash
      .removeClass(@SHOW_ANIMATION)
      .bind 'oanimationend animationend webkitAnimationEnd', ->
        $flash.hide()
      .addClass(@HIDE_ANIMATION)

  constructor: (msg, options={}) ->
    type     = options['type'] || 'info'
    autohide = options['autohide']
    autohide = type == "notice" if autohide == undefined

    @flash     = @buildFlash(msg, type)
    @container = $(".flash-messages").find("ul")
    @container.prepend(@flash.addClass("animation #{Flash.SHOW_ANIMATION}"))

    if autohide
      setTimeout =>
        Flash.hide(@flash)
      , @AUTOHIDE_DURATION

  buildFlash: (msg, type) ->
    icon = @getIcon(type)
    $("<li class='flash #{type}'><span>#{icon} #{msg}</span><a class='flash-close'>&times;</a></li>")

  getIcon: (type) ->
    iconName =
      switch type
        when "alert" then "exclamation-triangle"
        when "notice" then "check-circle"
        else "info-circle"

    "<i class='fa fa-#{iconName}'></i>"


$(document).on "js.load", ->
  # hide messages when clicked to close
  $(document).on "click", ".flash-close", ->
    $flash = $(this).closest(".flash")
    Flash.hide($flash)

  # show flash messages on page load
  $(".flash-input").each ->
    $input = $(this)
    msg = $input.val()
    options = $input.data()
    $input.remove()

    new Flash(msg, options)
