class DemographicFiscalYearForm
  constructor: ->
    $(".fiscal-year-toggle-field").on "change", (event) => @setStatus(event)
                                  .trigger("change")

  setStatus: (event) ->
    trigger = $(event.currentTarget)

    if trigger.is(":checked")
      @enableFY(trigger)
    else
      @disableFY(trigger)

  enableFY: (trigger) ->
    container = trigger.closest(".row")
    inputs = container.find(":input").not(trigger)
    button = container.find(".fiscal-year-toggle-button")
    labels = container.find("label").not(button)
    destroy = inputs.filter("[name$='[_destroy]']")

    button.addClass("enabled")
    labels.css("opacity", "1")
    inputs.removeAttr("disabled")

    if destroy.length > 0
      destroy.val(false)

  disableFY: (trigger) ->
    container = trigger.closest(".row")
    inputs = container.find(":input").not(trigger)
    button = container.find(".fiscal-year-toggle-button")
    labels = container.find("label").not(button)
    destroy = inputs.filter("[name$='[_destroy]']")

    button.removeClass("enabled")
    labels.css("opacity", "0.5")
    inputs.attr("disabled", "disabled")

    if destroy.length > 0
      destroy.val(true).removeAttr("disabled")



$(document).on "demographic_fiscal_years/edit.load", ->
  new DemographicFiscalYearForm()
