class window.Toggler
  @DEFAULT_SPEED: 400

  ######

  @show: (element, speed=@DEFAULT_SPEED, callback) -> new Toggler(element, speed, callback).show()
  @hide: (element, speed=@DEFAULT_SPEED, callback) -> new Toggler(element, speed, callback).hide()

  ######

  constructor: (element, speed, callback) ->
    @toggleable = $(element)
    @options    = @setOptions(speed, callback)
    @isShown    = @toggleable.is(":visible")

  show: (speed, callback) ->
    @isShown = true

    if $.isFunction(speed) && callback == undefined
      callback = speed
      speed = undefined

    speed ||= @options["speed"] unless $.isNumeric(speed)

    @toggleable.stop().slideDown speed, => @done(callback)

  hide: (speed, callback) ->
    @isShown = false

    if $.isFunction(speed) && callback == undefined
      callback = speed
      speed = undefined

    speed ||= @options["speed"] unless $.isNumeric(speed)

    @toggleable.stop().slideUp speed, => @done(callback)

  done: (callback) ->
    if $.isFunction callback
      callback.call(@toggleable)
    else if $.isFunction @options["afterToggle"]
      @options["afterToggle"].call(@toggleable)

  setOptions: (speed, callback) ->
    options = {speed: speed, afterToggle: callback}

    if $.isFunction(speed) && callback == undefined
      options["afterToggle"] = speed
      options["speed"]       = @DEFAULT_SPEED

    options["speed"] ||= @DEFAULT_SPEED
    options


# Used for initially hidden content that is displayed when trigger is clicked.
class Toggleable
  constructor: (trigger) ->
    @trigger    = $(trigger)
    selector    = @trigger.data("toggle")
    @toggleable = $(selector)
    @detach     = @trigger.data("detach-toggleable") || false


    @toggleable.appendTo("body") if @detach

    @toggler = new Toggler(@toggleable, "fast")

    # hide initially
    @hide(0)

    # toggle when trigger clicked
    @trigger.on "click", => @toggle(@toggler.isShown)

  triggerListeners: -> @trigger.trigger("toggleable.toggled", [@toggleable, @toggler.isShown])
  setPosition: ->
    triggerPos  = @trigger.offset()
    triggerW    = @trigger.outerWidth()
    triggerH    = @trigger.outerHeight()
    triggerTop  = triggerPos.top
    triggerLeft = triggerPos.left
    toggleableH = @toggleable.outerHeight()

    windowH      = $(window).height()
    windowW      = $(window).width()
    windowTop    = $(window).scrollTop()
    windowBottom = windowTop + windowH

    # toggleable shows underneath trigger unless it overflows viewport (then it shows above)
    bottomPos = triggerTop + triggerH + toggleableH
    rightPos  = windowW - (triggerLeft + triggerW)
    topPos    = if bottomPos > windowBottom then triggerTop - toggleableH else triggerTop + triggerH

    @toggleable.css
      "position" : "absolute"
      "right"    : rightPos
      "top"      : topPos

  toggle: (isShown) -> if isShown then @hide() else @show()
  hide: (speed) -> @toggler.hide speed, => @triggerListeners()
  show: (speed) ->
    @setPosition() if @detach

    @toggler.show speed, =>
      outsideClickListener = (event) =>
        target = $(event.target)

        if !target.closest(@toggleable).length && !target.closest(@trigger).length && @toggler.isShown
          @hide()
          removeClickListener()

      removeClickListener = ->
        document.removeEventListener("click", outsideClickListener)

      document.addEventListener("click", outsideClickListener)

      # reset fixed height so nested menus don't overflow when toggled
      @toggleable[0].style.height = "auto"
      @triggerListeners()


$(document).on "js.load", ->
  new Toggleable(trigger) for trigger in $("[data-toggle]")