class window.Ellipsis
  MAX_HEIGHT: 75
  DISPLAY_CLASS: "expanded"
  FIXED_CLASS: "keep-expanded"

  constructor: (element) ->
    @element = $(element)
    @element.on "click", ".ellipsis-toggle", =>
      @element.addClass(@FIXED_CLASS).addClass(@DISPLAY_CLASS)

    $(window).on "resize", => @reInit()
    @reInit()

  reInit: ->
    unless @element.hasClass(@FIXED_CLASS)
      @element.addClass(@DISPLAY_CLASS)
      @element.removeClass(@DISPLAY_CLASS) if @element.height() > @MAX_HEIGHT

$(document).on "js.load", ->
  new Ellipsis(ellipsis) for ellipsis in $(".ellipsis")
