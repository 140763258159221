moment = require 'moment'

class window.ConflictSearch
  LIST_SELECTOR: ".conflict-search-list"
  LIST_TOGGLE_SELECTOR: "#search-list-toggle"
  FIELD_SELECTOR: ":input[data-conflict-search-field]"
  PLACEHOLDER: "<span class='small-font muted'><i class='fa fa-spinner fa-pulse'></i> Looking for conflicts...</span>"

  constructor: (form) ->
    @form = $(form)
    @list = @form.find(@LIST_SELECTOR)

    @init() if @list.length > 0

  init: ->
    @loadPath = @list.data("conflict-search")
    @setTriggers()

  setTriggers: ->
    @form.on "change", @FIELD_SELECTOR, => @search()

    # button search triggers
    @form.find("[data-conflict-search-trigger].add-association").on "association.added", => @search()
    @form.on "click", "[data-conflict-search-trigger]:not(.add-association)", =>
      setTimeout((() => @search()), 500) # use setTimeout so it runs after fields removed

    # when conflicts are found, this trigger is for the toggle to show the list
    @list.on "click", @LIST_TOGGLE_SELECTOR, (e) =>
      toggle = $(e.currentTarget)
      section = toggle.next()
      section.stop().slideToggle ->
        toggle.text if section.is(":visible") then "Hide List" else "Show List"

  search: ->
    values = @getValidValues()

    if values.length > 0 && @changedMinOrMax(values)
      @loadList(values)
    else
      @list.html("") # clear list

  loadList: (values) ->
    @list.html(@PLACEHOLDER)
    $.get @loadPath, {dates: values}, (response) =>
      @list.html(response)

    true

  changedMinOrMax: (newValues) ->
    newMin = @getMinValue(newValues)
    newMax = @getMaxValue(newValues)

    if !(@prevValues?) || (newMin != @prevValues[0] || newMax != @prevValues[1])
      @prevValues = [newMin, newMax]
      return true

    false

  # pull min start date from list of values
  getMinValue: (values) ->
    currentMin = null

    for val in values
      newMin = @parseValidDates(val["start_date"])
      currentMin = newMin if currentMin == null || currentMin > newMin

    currentMin

  # pull max end date from list of values
  getMaxValue: (values) ->
    currentMax = null

    for val in values
      newMax = @parseValidDates(val["end_date"])
      currentMax = newMax if currentMax == null || currentMax < newMax

    currentMax

  getValidValues: ->
    validValues = []
    fields = @form.find(@FIELD_SELECTOR)
    groups = {}

    for field in fields
      $field = $(field)

      groupId = "#{$field.data("conflict-search-field")}"
      groupKey = if /start_date/.test($field.attr("name")) then "start_date" else "end_date"

      groups[groupId] = {} unless groups.hasOwnProperty(groupId)
      groups[groupId][groupKey] = $field.val()

    # go through groups and only add valid values to validValues
    $.each groups, (_, values) => validValues.push(values) if @isValidValues(values)

    return validValues

  isValidValues: (values) ->
    sdate = @parseValidDates(values['start_date'])
    edate = @parseValidDates(values['end_date'])
    sdate != null && edate != null && sdate <= edate

  # if date is valid, returns Date object; otherwise returns null
  parseValidDates: (value) ->
    return null unless typeof(value) == "string"
    return null unless /\d{2}\/\d{2}\/\d{4}/.test(value)

    moment(value, "MM/DD/YYYY").toDate()
