`import { DirectUpload } from "@rails/activestorage"`

class window.FileUpload
  @upload: (input, onSuccess) ->
    new FileUpload(input, onSuccess).upload()

  constructor: (input, onSuccess = null) ->
    @input = $(input)
    @onSuccess = onSuccess
    @queue = new Array
    @uploadUrl = @input.data("directUploadUrl")

  upload: ->
    @queue.push(file) for file in @input[0].files

    for file in @queue
      upload = new DirectUpload(file, @uploadUrl, this)
      upload.create (error, blob) =>
        if (error)
          # Handle the error
          # Shouldn't submit form since file doesn't get removed from queue
          alert(error)
        else
          # Add an appropriately-named hidden input to the form with a
          # value of blob.signed_id so that the blob ids will be
          # transmitted in the normal upload flow
          hiddenField = $("<input type='hidden'/>")
          hiddenField.attr("value", blob.signed_id)
          hiddenField.attr("name", @input.attr('name'))
          @input.before(hiddenField)

          # remove file from queue
          @queue.splice(@queue.indexOf(file), 1)

          @afterUpload()

  directUploadWillStoreFileWithXHR: (request) ->
    new FileUploadProgress(@input, request.upload)

  afterUpload: ->
    unless @queue.length > 0
      # disable input so only hidden fields are submitted
      @input.attr("disabled", "disabled")
      @onSuccess() if $.isFunction(@onSuccess)


class window.FileUploadProgress
  constructor: (input, upload) ->
    @upload = upload
    @input = input
    @progressBar = $("<div class='bar' style='display: block; width: 0%;'>Uploading...</div>")
    @barContainer = $("<div class='progress'></div>")

    @start()

  start: ->
    @input.after(@barContainer.append(@progressBar))
    @upload.addEventListener "progress", (event) => @updateProgress(event)

  updateProgress: (event) ->
    percent = event.loaded / event.total * 100
    @progressBar.css("width", "#{percent}%")
    @complete() if percent >= 100

  complete: -> @progressBar.text("Done.")