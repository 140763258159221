class UserFields
  constructor: ->
    @form = $(".user-form")
    @adminCheckbox = @form.find("[name$='[admin]']:not(:hidden)")

    @initAdminFields() if @adminCheckbox.length > 0

  initAdminFields: ->
    trainingManagerCheckbox = @form.find("[name$='[training_manager]']:not(:hidden)")
    exerciseManagerCheckbox = @form.find("[name$='[exercise_manager]']:not(:hidden)")

    trainingManagerHidden = @form.find("[name$='[training_manager]']:hidden")
    exerciseManagerHidden = @form.find("[name$='[exercise_manager]']:hidden")
    allManagerHidden      = trainingManagerHidden.add(exerciseManagerHidden)

    # enable manager checkbox when admin checkbox enabled
    @adminCheckbox.on "change", =>
      if @adminCheckbox.is(":checked")
        # must click so the manager subfields are triggered
        trainingManagerCheckbox.click() if !trainingManagerCheckbox.is(":checked")
        trainingManagerCheckbox.attr("disabled", "disabled")
        exerciseManagerCheckbox.click() if !exerciseManagerCheckbox.is(":checked")
        exerciseManagerCheckbox.attr("disabled", "disabled")

        # must set hidden field value since checkbox value is disabled and won't submit
        allManagerHidden.val("1")
      else
        trainingManagerCheckbox.removeAttr("disabled")
        exerciseManagerCheckbox.removeAttr("disabled")
        # reset hidden field value to original value
        allManagerHidden.val("0")

    .trigger "change"


$(document).on "users/edit.load users/update.load", ->
  new UserFields()