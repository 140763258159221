class Report
  ERROR_MSG: "<span><i class='fa fa-warning'></i> An error occurred while loading this report.</span>"

  constructor: ->
    @report       = $(".report-container")
    @exportButton = $(".export-modal-button").attr("disabled", "disabled")

    @loadReport()

  placeholder: -> @report.find(".report-placeholder")

  poll: (url) ->
    $.get url, (resp, textStatus, xhr) =>
      # Continue polling if 204 (No Content) or 206 (Partial Content) status code is returned
      if xhr.status == 200
        content = $(resp)

        @placeholder().replaceWith(content)

        # add tooltips
        Tooltip.init(content.find("[data-tooltip]"))

        # enable exports
        @exportButton.removeAttr("disabled")

        # trigger for listeners to know when report is loaded
        $(document).trigger("reports.loaded")
      else if xhr.status == 202
        setTimeout =>
          @poll(url)
        , 2000

    .fail => @placeholder().addClass("error-msg").html(@ERROR_MSG)

  loadReport: ->
    ph = @placeholder()

    @poll(ph.data("load-path")) if ph.length > 0

$(document).on "js.load", ->
  new Report if Env.controller().split("/").length > 1 && Env.controller().split("/")[0] == "reports"
