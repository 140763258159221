class TrackFormChanges
  constructor: (form) ->
    @form     = $(form)
    @fieldIdx = 0
    @origVals = {}

    @trackChanges()

  trackChanges: ->
    # store original values to compare with on submission
    for trackedField in @trackedFields()
      @setChangeTrackedFieldIndex(trackedField)

    # intercept form submission to check if any of the tracked fields have changed
    @form.on "submit", (e) =>
      # remove action if it's canceled, but replace it if they say yes
      if @anyChanges() && !@acceptChanges()
        if (@form.attr("data-action") == undefined)
          @form.attr("data-action", @form.attr("action"))
        @form.attr("action", "")
      else
        @form.attr("action", @form.attr("data-action"))

  trackedFields: -> @form.find("[data-track-change]")

  # prompt user to tell them we'll be notifying participants of changes
  acceptChanges: ->
    confirm "Looks like there were changes made to the location or time. " +
            "Registered participants will be notified of these changes. " +
            "Are these changes final?\n\nNOTE: refresh page to reset fields."

  # compare current values with original values and return 'true' if any don't match
  anyChanges: ->
    for trackedField in @trackedFields()
      field = $(trackedField)
      index = field.data("track-change")

      # index hasn't been set, so it's a new field and therefore has changed
      return true if typeof(index) == "string" && /\d+/.test(index)

      newVal  = field.val()
      changed = @origVals[index] != newVal

      return true if changed

    false

  setChangeTrackedFieldIndex: (trackedField) ->
    field = $(trackedField)
    field.data("track-change", @fieldIdx)

    # set value at index saved in field's data attr for retrieval later
    @origVals[@fieldIdx] = field.val()

    # increment index for next field
    @fieldIdx++

class CourseOfferingSummary
  constructor: ->
    @completionCertificateModal()

  completionCertificateModal: ->
    $(".course-completion-button").on "modal:loaded", (e, modal) =>
      m          = $(modal.modal)
      testBtns   = m.find("input[name$='[include_test]']")
      testFields = m.find("#participants-scores")
      partList   = m.find("#participants-list")

      testBtns.on "change", ->
        val = testBtns.filter(":checked").val()
        if val == "true"
          partList.hide()
          testFields.show()
        else
          testFields.hide()
          partList.show()

        # check if modal content overflows window
        modal.checkOverflow()

      m.find(".select_all").on "change", ->
        m.find(".status-#{$(this).data('select-all')}").each (idx, btn) =>
          $(btn).prop("checked", true)

      certBtns   = m.find("input[name$='[include_cert]']")
      certFields = m.find("#cert-subfields")

      if certFields.length > 0
        postCertBtns = certFields.find("input[name$='[include_post_on_cert]']")
        certBtns.on "switch.switched", (e) ->
          value = certBtns.filter(":checked").val()
          validated = postCertBtns.filter("[data-parsley-required]")
          validated.attr("data-parsley-required", value == "true")

          # check if modal content overflows window
          modal.checkOverflow()

        certBtns.first().trigger("switch.switched")

class CourseOfferingForm
  constructor: ->
    @form = $("form.course-offering-form")
    @facilityField = @form.find(":input[name$='[facility_id]']")
    @audienceField = @form.find(":input[name$='[audience]']")
    @approvalTypeField = @form.find(":input[name$='[manual_approval]']")

    @initForm()
    console.log @form.data("track-changes")
    new TrackFormChanges(@form) if @form.data("track-changes")

  initForm: ->
    @form.on "submit", (e) =>
      if @canceledFundingSourceRemoval()
        e.preventDefault()
        e.stopPropagation()

    @form.find(".new-option-modal[data-modal]").each (idx, el) =>
      trigger   = $(el)
      fieldName = trigger.data("modal-field")

      trigger.on "modal:loaded", (e, modal) => @initModal(modal, fieldName)

    @audienceField.on "change", (e) =>
      @setApprovalType(@audienceField.val() == "invite_only")
    .trigger "change"

    # initialize selectize
    new FacilitySearch(@facilityField)

    @form.find(".add-association").on "association.added", (event, newFields) =>
      @copyPrevFieldValues(newFields)
      DateInput.initInputs(newFields) # init date inputs

    @form.on "click", ".remove-association", (event) ->
      button = $(event.currentTarget)
      fields = button.closest(".date-and-time-fields")

      fields.slideUp ->
        if fields.data("persisted")
          idField     = fields.next("[name$='[id]']")
          deleteField = idField.clone()
          deleteField.attr "name", (idx, val) -> val.replace(/\[id\]/, "[_destroy]")

          fields.replaceWith(deleteField)
        else
          fields.remove()


  copyPrevFieldValues: (newFields) ->
    fields     = $(newFields)
    prevFields = fields.prevAll(".date-and-time-fields").first()

    # copy previous field values to new fields
    if prevFields.length > 0
      prevEndDateField = prevFields.find("[name$='[end_date]']")
      prevTimeField    = prevFields.find("[name$='[time]']")

      fields.find("[name$='[start_date]'], [name$='[end_date]']").val(prevEndDateField.val())
      fields.find("[name$='[time]']").val(prevTimeField.val())

  addAndSelectNewOption: (option, fieldName) ->
    switch fieldName
      when "facility"
        # add new options to dropdown and make selected option
        selectize = @facilityField[0].selectize
        selectize.addOption({text: option.name, value: option.id, address: option.address})
        selectize.setValue(option.id)

      when "vendor"
        select = @form.find("select[name$='[vendor_id]']")
        select.append("<option value='#{option.id}'>#{option.name}</option>")
        select.val(option.id)

      when "sponsor"
        select = @form.find("select[name$='[sponsor_ids][]']")
        selectize = select[0].selectize
        selectize.addOption({text: option.name, value: option.id})

        # get current value so we don't clear selected values when setting new value
        newVal = selectize.getValue()
        newVal.push option.id

        selectize.setValue(newVal)

  initModal: (modal, fieldName) ->
    modalElement = $(modal.modal)
    modalForm    = modalElement.find("form")

    modalForm.on "ajax:success", (event, resp) =>
      # adds option to field and selects as current value
      @addAndSelectNewOption(resp, fieldName)

      modal.close() # hide modal
      Form.reset(modalForm) # clear fields and errors

  setApprovalType: (disable) ->
    if disable
      @approvalTypeField.addClass("disabled").find("option").attr("disabled", "disabled").filter("[value='true']").removeAttr("disabled").prop("selected", true)
    else
      @approvalTypeField.removeClass("disabled").find("option").removeAttr("disabled")

  # checks if we need to warn user for removing a funding source that's linked to offering costs
  canceledFundingSourceRemoval: ->
    field = @form.find("select[name$='[funding_source_ids][]']")
    sources = $.extend({}, field.data("original-funding-sources")) # must use $.extend to clone the object so the original doesn't get modified

    return false if typeof(sources) != 'object' || $.isEmptyObject(sources)

    for id, name of sources
      delete sources[id] if id in field.val()

    return false if $.isEmptyObject(sources)

    sourcesText = "\n"
    sourcesText += "\n#{name}" for id, name of sources

    !confirm "You removed the following funding sources that were still linked to offering costs: #{sourcesText}\n\n" +
             "Are you sure you want to continue? You will need to manually update the costs if necessary."

$(document).on "course_offerings/new.load course_offerings/create.load course_offerings/edit.load course_offerings/update.load", ->
  new CourseOfferingForm()

$(document).on "course_offerings/show.load", ->
  new CourseOfferingSummary()