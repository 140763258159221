class CancelledReports
  constructor: ->
    $(document).on "reports.loaded", => @initReport()

  initReport: ->
    for trigger in $(".report-container .editable")
      new CancelledModal(trigger)

class CancelledModal
  constructor: (trigger) ->
    @trigger = $(trigger)
    @trigger.on "modal:loaded", (_, modal) =>
      m = $(modal.modal)
      form = m.find("form")

      # init toggle fields
      new CancellationForm(form)

      form.on "ajax:success", (_, resp) =>
        @updateContent(resp)
        modal.close()

        new Flash("Successfully updated cancellation details!", {type: 'notice'})

  updateContent: (data) ->
    note = data.cancellation_note
    note = "<em class='muted'>None Set</em>" if note == ""

    row = $("tr[data-id='#{data.id}']")
    row.find(".editable").html data.cancellation_reason
    row.find("td:last").html note


class CancellationForm
  constructor: (form) ->
    @form = $(form)
    @init()

  init: ->
    cancelReasonField = @form.find(":input[name$='[cancellation_reason_id]']")
    specifyReasonField = @form.find(":input[name$='[cancellation_reason_specify]']")
    cancelReasonToggler = new Toggler(@form.find("#cancellation-reason-subfields")[0])

    cancelReasonField.on "change", ->
      if cancelReasonField.find("option:selected").data("other")
        specifyReasonField.attr("required", "required")
        cancelReasonToggler.show()
      else
        specifyReasonField.removeAttr("required")
        cancelReasonToggler.hide()


$(document).on "exercise_cancellations/edit.load exercise_cancellations/update.load course_offering_cancellations/edit.load course_offering_cancellations/update.load", ->
  new CancellationForm $(".cancellation-form")

$(document).on "reports/cancelled_exercises_reports/show.load reports/cancelled_course_offerings_reports/show.load", ->
  new CancelledReports()