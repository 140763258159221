`import tippy from 'tippy.js'`

class window.Tooltip
  @init: (elements) ->
    new Tooltip(element) for element in elements

  constructor: (element) ->
    tippy element,
      content: ->
        title = element.getAttribute('title')

        # remove title so it doesn't show native popup message
        element.removeAttribute('title')

        return title
