class window.CourseSearch
  constructor: (select, options={}) ->
    @select = $(select)
    @setup(options)

  @init: (container) ->
    container.find("select.course-search").each (idx, s) => new CourseSearch(s)

  setup: (options) ->
    selectOptions = $.extend({}, @defaultOptions(), options)
    @select.selectize selectOptions

  getExcluded: ->
    selected = @select.val()
    excluded = @select.data("excluded")
    excluded = new Array if excluded == undefined

    if selected?
      if $.isArray(selected) then (excluded += selected) else excluded.push(selected)

    excluded

  defaultOptions: ->
    availableOptions = @select.data("available-options") || []
    availableOptions = [availableOptions] unless $.isArray(availableOptions)
    selectedOptions  = @select.data("selected-options") || []
    selectedOptions  = [selectedOptions] unless $.isArray(selectedOptions)

    {
      valueField: 'id'
      labelField: 'code'
      searchField: 'code'
      options: availableOptions
      items: selectedOptions
      render:
        option: (item, escape) ->
          '<div>' +
            '<span class="name">' +
              '<strong>' + escape(item.code) + '</strong><span class="muted"> &mdash; ' + escape(item.title) + '</span>' +
            '</span>' +
          '</div>'

      load: (query, callback) =>
        $.ajax
          url: @select.data("load-path"),
          type: 'GET'
          dataType: 'json'
          data: {q: query, excluded: @getExcluded()}
          error: => callback()
          success: (res) => callback(res)
    }

class CourseForm
  constructor: ->
    @form            = $("form.course-form")
    @prereqSubfields = @form.find("#prereq-subfields")
    @prereqList      = @form.find("#prereq-list")
    @prereqIdx       = 0 # used for unique ID for grouping

    @ceuFieldToggler         = new Toggler(document.getElementById("ceu-subfields"))
    @prereqFieldToggler      = new Toggler(document.getElementById("prereq-subfields"))
    @disciplinesFieldToggler = new Toggler(document.getElementById("discipline-subfields"))

    @initForm()

  initForm: ->
    providerField = @form.find("select[name$='[provider]']")
    providerField.on "change", (e) =>
      if providerField.val() == "merc" then @ceuFieldToggler.show() else @ceuFieldToggler.hide()

    disciplineField = @form.find("select[name$='[discipline_ids][]']")
    disciplineField.on "change", (e) =>
      if disciplineField.val().length > 0 then @disciplinesFieldToggler.show() else @disciplinesFieldToggler.hide()

    @setupPrerequisiteFields()

  buildPrerequisite: (selectedCourses) ->
    prereqHTML = $("<li class='prereq-item' data-id='#{@prereqIdx}'></li>")
    prereqHTML.append("<div class='prereq-actions'></div>")

    $.each selectedCourses, (idx, course) =>
      prereqHTML.append("<span class='prereq-item-separator'> OR </span>") if idx > 0
      prereqHTML.append "<span class='prereq-item-code'>#{course.code}</span>"
      prereqHTML.append("<input type='hidden' name='course[active_course_instance_attributes][prerequisite_courses][#{@prereqIdx}][course_ids][]' multiple value='#{course.id}'/>")

    editModalPath = @prereqList.data("edit-path").replace ":id", @prereqIdx
    editModalPath += "?" + prereqHTML.find(":input").serialize()

    prereqHTML.find(".prereq-actions")
              .append("<button type='button' class='prereq-item-edit-button button-link' data-modal='#{editModalPath}'><i class='fa fa-pencil-alt'></i><span class='none inline-m'> Edit</span></button>")
              .append("<button type='button' class='prereq-item-remove-button button-link'><i class='fa fa-trash'></i><span class='none inline-m'> Remove</span></button>")

    @prereqIdx++ # increment for next prerequisite

    prereqHTML

  addPrerequsite: (courses) ->
    prereqHTML = @buildPrerequisite(courses).hide()

    @prereqList.append(prereqHTML)

    prereqHTML.slideDown(350)
    @prereqFieldToggler.show()

  updatePrerequisite: (id, courses) ->
    prereq = @prereqList.find(".prereq-item[data-id='#{id}']")
    prereq.replaceWith(@buildPrerequisite(courses))

  removePrereq: (prereq) ->
    prereq.slideUp 350, (e) =>
      prereq.remove()
      @prereqFieldToggler.hide() if @prereqList.find(".prereq-item").length <= 0

  hidePrerequisiteForm: (form, destroy = false) ->
    modal = form.closest(".tingle-modal")
    modal.find(".tingle-modal__close:visible").trigger('click') # trigger close to disable body scroll-locking (on destruction)
    modal.remove() if destroy

  getCourseValues: (selectObj, clear = false) ->
    selectize = selectObj[0].selectize
    values = []

    # pull attributes from selectize for selected courses
    for val in selectize.getValue()
      values.push selectize.options[val]

    # reset selection (setTimeout prevents field to be cleared before values are pulled)
    setTimeout((() -> selectize.clear()), 250) if clear

    return values

  submitPrerequisiteForm: (form) ->
    form = $(form)
    id = form.data("id")
    searchField = form.find(".course-search:input")

    if id == undefined
      @addPrerequsite(@getCourseValues(searchField, true))
      @hidePrerequisiteForm(form)
    else
      @updatePrerequisite(id, @getCourseValues(searchField))
      @hidePrerequisiteForm(form, true)

  setupPrerequisiteFields: ->
    selectedPrereqs = @prereqList.data("selected")

    if selectedPrereqs
      @addPrerequsite(prereqCourses) for prereqCourses in selectedPrereqs

    @prereqList.on "click", ".prereq-item-remove-button", (event) =>
      event.preventDefault()
      prereq = $(event.currentTarget).closest(".prereq-item")
      @removePrereq(prereq)

    # for prerequisite add AND edit forms
    $(document).on "submit", ".prerequisite-form", (e) =>
      e.preventDefault()
      @submitPrerequisiteForm(e.currentTarget)

class CourseVisibilityToggle
  constructor: (toggle) ->
    @toggle = $(toggle)
    @form = @toggle.find("form")
    @button = @form.find(":submit")
    @tippy = @form[0]._tippy

    @form.on "ajax:success", (e, data) => @updateStatus(data.visible)

  updateStatus: (isVisible) ->
    oldBtn = @button

    if isVisible
      @tippy.setContent('Course is currently visible to non-admins')
      @button = @button.clone()
      @button.removeClass('warning').addClass('success')
      @button.find(".fa").removeClass('fa-spin fa-spinner fa-times').addClass("fa-check")
    else
      @tippy.setContent('Course is currently NOT visible to non-admins')
      @button = @button.clone()
      @button.removeClass('success').addClass('warning')
      @button.find(".fa").removeClass('fa-spin fa-spinner fa-check').addClass("fa-times")

    oldBtn.replaceWith @button

$(document).on "courses/index.load", -> new CourseVisibilityToggle(toggle) for toggle in $(".course-toggle")
$(document).on "courses/new.load courses/create.load courses/edit.load courses/update.load", -> new CourseForm()
$(document).on "js.load", -> new CourseSearch(searchField) for searchField in $(".course-search")
