class ExportFormSubmission
  constructor: (data) ->
    @data = data
    @list = $(".export-list")
    @listItem = $("<tr class='export-item' id='export-#{@data.id}'></tr>")
    @init()
    $(".tingle-modal-box")[0].setAttribute("style", "height: auto") unless $(".tingle-modal-box")[0].style["height"] == "auto"

  init: ->
    @listItem.append("<td>#{@data.exported_on}</td>")
    @setStatus(@data)
    @list.find("tbody").prepend(@listItem)

  setStatus: (data) ->
    status =
      switch data.status
        when "enqueued", "pending"
          @recheckStatus()

          "<td class='status'><i class='fa fa-spinner fa-pulse'></i> Exporting...</td>"
        when "generated"
          "<td class='status'><a href='#{data.export_url}'>#{data.export_name}</a></td>"

    if @listItem.find(".status").length > 0
      @listItem.find(".status").replaceWith(status)
    else
      @listItem.prepend(status)


  recheckStatus: ->
    setTimeout =>
      $.get "/exports/#{@data.id}", (resp) => @setStatus(resp)
    , 3500

class ExportModal
  constructor: (modal) ->
    @modal = $(modal)
    @settingsForm = @modal.find(".export-settings-form")
    @setup()

  setup: ->
    @modal.find(".export-form").on "ajax:success", (event, resp) ->
      new ExportFormSubmission(resp)

    checkBox = @settingsForm.find("input[type='checkbox']")
    checkBox.on "change", =>
      data = @settingsForm.serialize()
      path = @settingsForm.attr("action")

      checkBox.attr("disabled", "disabled")

      $.post path, data, (response) ->
        checkBox.removeAttr("disabled")

    $(".tingle-modal-box")[0].setAttribute("style", "height: 115%")

class ExportButton
  constructor: (button) ->
    $(button).on "modal:loaded", (e, modal) -> new ExportModal(modal.modal)

$(document).on "js.load", ->
  for button in $(".export-modal-button")
    new ExportButton(button)

