Pikaday = require("pikaday")

class window.DateInput
  @initInputs: (scope) ->
    inputs = if scope == undefined then $("input.date-input") else $(scope).find("input.date-input")
    inputs.each -> new DateInput(this)

  constructor: (input) ->
    mask = new Inputmask {
      regex: "(0\\d|1[0-2])/(0\\d|1\\d|2\\d|3[0-1])/\\d{4}"
      placeholder: "mm/dd/yyyy"
    }

    # disable browser suggestions
    $(input).attr("autocomplete", "off")

    yearRange = JSON.parse input.getAttribute("data-year-range")
    options = {
      field: input
      format: "MM/DD/YYYY"
      yearRange: yearRange
    }

    minDate = input.getAttribute("data-min-date")
    maxDate = input.getAttribute("data-max-date")

    options["minDate"] = new Date(minDate) if minDate?
    options["maxDate"] = new Date(maxDate) if maxDate?

    if input.value == ""
      options["defaultDate"] = options["minDate"] if options["minDate"] != undefined && options["minDate"] > new Date()
      options["defaultDate"] = options["maxDate"] if options["maxDate"] != undefined && options["maxDate"] < new Date()

    mask.mask input

    new Pikaday options

    if screen.width < 1200
      $(input).on "click", ->
        $(document).on 'keydown', (event) ->
          backspaceCheck(event, input)

      $(input).on "change", ->
        document.removeEventListener 'keydown', backspaceCheck

  backspaceCheck = (event, input) ->
    keyID = event.keyCode
    sDate = $(input)
    sDateVal = sDate.val()

    if /[0-3]d\/yyyy/.test(sDateVal) && (keyID == 8 || keyID == 46) # if 1 digit of day is left and backspace or delete is pressed
      event.preventDefault();
      sDate.val(sDateVal.split("/")[0])
      sDate[0].setSelectionRange(2,2);
    else if /[0-1]m\/dd\/yyyy/.test(sDateVal) && (keyID == 8 || keyID == 46) # if 1 digit of month is left and backspace or delete is pressed
      sDate.val("mm/dd/yyyy")

$(document).on "js.load", -> DateInput.initInputs()
