moment = require('moment')

class window.Formatter
  @formatDate: (date) ->
    moment(date).format("MMM D, YYYY")

  @formatDateTime: (datetime) ->
    moment(datetime).format("MMM D, YYYY h:mm A")

  @capitalize: (text) ->
    text.charAt(0).toUpperCase() + text.slice(1)
