# Use check box or radio buttons element to toggle another element
# Must set [data-switch] attribute to the select of the element being toggled
#
# Example:
#   <input type='checkbox' data-switch='#something'/> will show #something when checked and hide when unchecked

class SwitchToggler
  constructor: (input) ->
    @trigger    = $(input)
    @toggleable = $(@trigger.data("switch"))
    @invert     = @trigger.data("invert-switch") || false
    @toggler    = new Toggler(@toggleable)

    @switch()

  switch: ->
    display = @trigger.prop("checked")
    display = !display if @invert

    if display then @toggler.show(() => @afterSwitch()) else @toggler.hide(() => @afterSwitch())

  afterSwitch: ->
    # for event listeners
    @trigger.trigger("switch.switched")

$(document).on "click", "[data-switch]", (e) -> new SwitchToggler(e.target)
