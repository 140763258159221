class CourseOfferingParticipantForm
  constructor: (form) ->
    @form = $(form)
    @statusField = @form.find("select[name$='[status]']")
    @initForm()

  initForm: ->
    cancelSubField = @form.find("#cancel-reason-subfields")
    noticeSubField = @form.find("#acceptance-notice-subfields")
    cancelToggler  = new Toggler(cancelSubField)
    noticeToggle   = new Toggler(noticeSubField)

    @statusField.on "change", =>
      if @statusField.val() == "cancelled" then cancelToggler.show() else cancelToggler.hide()
      if @statusField.val() == "registered" then noticeToggle.show() else noticeToggle.hide()

$(document).on "course_offering_participants/new.load " +
               "course_offering_participants/create.load " +
               "course_offering_participants/edit.load " +
               "course_offering_participants/update.load", ->

  forms = $(".registration-form")
  new CourseOfferingParticipantForm(form) for form in forms
