class Filters
  COLLAPSED_HTML: "Show <i class='fa fa-fw fa-plus-square'></i>"
  EXPANDED_HTML: "Hide <i class='fa fa-fw fa-minus-square'></i>"

  constructor: (filters) ->
    @filters    = $(filters)
    @header     = @filters.find(".filters-header")
    @button     = @filters.find(".filters-button")
    @toggleable = @filters.find(".filters-toggleable")

    @form        = @toggleable.find("form")
    @statusField = @filters.find("#show_filters")

    @initFilters()

  initFilters: ->
    @statusField.appendTo(@form) if @form.length > 0 # move to form so field so state persists after form submission
    @toggle(0) if @statusField.val() == "true"

    @addToggleListener()

  addToggleListener: -> @header.on "click", => @toggle()

  toggle: (speed=250) ->
    @toggleable.stop().slideToggle speed, =>
      @toggleable.css("height", "auto") # must reset so height isn't fixed if screen resizes

      isHidden = @toggleable.is(":hidden")
      linkHtml = if isHidden then @COLLAPSED_HTML else @EXPANDED_HTML

      @button.html(linkHtml)
      @statusField.val !isHidden

$(document).on "js.load", ->
  filters = $(".filters")

  new Filters(f) for f in filters