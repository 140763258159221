import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';

// # FullCalendar = require('fullcalendar/main');

// # class Calendar
// #   constructor: ->
// #     console.log require('pikaday')

// #     console.log require('fullcalendar')
//     # console.log new FullCalendar
//     # calendar = new FullCalendar.Calendar document.getElementById("calendar"), {
//     #    initialView: 'dayGridMonth'
//     # }

//     # calendar.render()
//     # $calendar = $("#")
//     # $calendar.fullCalendar
//     #   defaultDate: $calendar.data('default-date'),
//     #   validRange:
//     #     start: $calendar.data('min-date')
//     #   events:
//     #     url:   'calendar/load_events',
//     #     cache: true
//     #   eventClick: (calEvent, jsEvent, view) ->
//     #     window.location = '/courses/' + calEvent.course_id + '/offerings/' + calEvent.offering_id
//     #   eventAfterRender: (calEvent, jsEvent, view) ->
//     #     jsEvent.addClass("fc-event-cancelled") if calEvent.cancelled


class TrainingCalendar {
  constructor(calElement) {
    // console.log(calElement.dataset.defaultDate)
    this.calendar = new Calendar(calElement, {
      plugins: [ dayGridPlugin ],
      initialView: 'dayGridMonth',
      initialDate: calElement.dataset.defaultDate,
      eventColor: "#1ba3ef",
      events: {
        url:  'calendar/load_events',
        cache: true
      }
      // eventClick: function(info) {
      //   // console.log(info)
      //   let event = info.event
      //   console.log(event)
      //   window.open('/courses/' + event.course_id + '/offerings/' + event.offering_id);

      //   info.jsEvent.preventDefault();
      // }
    });
    // console.log(Calendar)
    // this.form = $(form)
    // this.submits = this.form.find(":submit")
    // this.inputs = this.form.find(":input[name^='client_intake']")
    // this.listTable = $("table#client-list")
    // this.requests = []
    // this.addTriggers()

    this.init()
  }

  init() {
    this.calendar.render()
  }
}

$(document).on("calendar/index.load", function(){
  var calendar = document.getElementById("calendar")
  new TrainingCalendar(calendar)
});