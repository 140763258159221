tingle = require("tingle.js")

class window.Modal
  PLACEHOLDER: "<i class='fa fa-spinner fa-pulse'></i> Loading content..."
  LOAD_ERROR: "<span style='color: red'>An error occurred while loading content.</span>"

  @initForms: (modal) ->
    m = $(modal.modal)
    Form.initForms(m)

  constructor: (activator) ->
    init = activator.getAttribute("data-modal-init") != "true"
    return false unless init

    activator.setAttribute("data-modal-init", "true")
    @activator = $(activator)
    @modal = new tingle.modal()
    @initModal()

  initModal: ->
    @addContent()

    @modal.open()

    $(@modal.modal).on "click", "[data-modal-close]", (e) =>
      e.preventDefault()
      @modal.close()

    @activator.on "click", => @modal.open()

  addContent: ->
    target = @activator.data("modal")

    if target[0] == "/"
      @modal.setContent(@PLACEHOLDER)

      $.get target, (response) =>
        @modal.setContent(response)
        @initNewModalContent()

        # trigger listeners
        @activator.trigger("modal:loaded", @modal)
      .fail () =>
        @modal.setContent(@LOAD_ERROR)
    else if target != ""
      $content = $(target).detach()
      @modal.setContent($content[0])

  initNewModalContent: ->
    container = $(@modal.modal)

    Form.initForms(container)
    DateInput.initInputs(container)
    CourseSearch.init(container)
    DemographicSearch.init(container)

$(document).on "click", "[data-modal]", (e) ->
  e.preventDefault()
  new Modal(e.currentTarget)
