class RegistrationReviewModal
  constructor: (modal, trigger) ->
    @modal = modal
    @trigger = trigger
    @container = $(@modal.modal)
    @forms = @container.find("form") # multiple forms

    @init()

  init: ->
    denialSwitch = @container.find("[data-switch]")
    denialSwitch.on "switch.switched", => @modal.checkOverflow()

    @forms.on "ajax:success", (event, resp) =>
      body = $('body')
      msg =
        switch resp.status
          when "registered" then "<span class='success-text'>Applicant Approved!</span>"
          when "denied" then "<span class='warning-text'>Applicant Denied.</span>"

      console.log resp.status
      console.log msg
      postContent = $(msg).hide()

      @modal.destroy()
      @trigger.replaceWith(postContent.slideDown())

class RegistrationReviewButton
  constructor: (button) ->
    @button = $(button)
    @button.on "modal:loaded", (e, modal) =>
      new RegistrationReviewModal(modal, @button)

$(document).on "js.load", ->
  reviewBtns = $(".registration-review-button")
  new RegistrationReviewButton(reviewBtn) for reviewBtn in reviewBtns